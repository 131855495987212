import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VideoPlayer from '../videoplayer/videoplayer.js';
import './cases_video.css';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'; // Import the useAuthHeader hook

const API_URL = process.env.REACT_APP_API_URL;
const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_LINK;

function CasesVideo() {
    const { videoId } = useParams();
    const [videoMetadata, setVideoMetadata] = useState(null);
    const [error, setError] = useState(null);
    const authHeader = useAuthHeader(); // Get the authorization token

    useEffect(() => {
        const fetchVideoMetadata = async () => {
            try {
                const token = authHeader.split(' ')[1]; // Extract the token

                // Fetch the video metadata
                const response = await axios.get(`${API_URL}/video/${videoId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the request headers
                    },
                });

                if (response.status === 200) {
                    const metadata = response.data;

                    // Fetch surgeon names for the operatedBy list
                    const surgeonIds = metadata.operatedBy; // Assume this is an array of IDs
                    const surgeonNames = await Promise.all(
                        surgeonIds.map(async (id) => {
                            try {
                                const surgeonResponse = await axios.get(`${API_URL}/user/name/${id}`, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                });
                                return surgeonResponse.data.name; // Assuming the API returns { name: 'Surgeon Name' }
                            } catch (error) {
                                console.error(`Failed to fetch name for surgeon ID: ${id}`, error);
                                return 'Unknown Surgeon'; // Fallback in case of an error
                            }
                        })
                    );

                    // Update metadata with surgeon names
                    metadata.operatedBy = surgeonNames.join(', ');
                    setVideoMetadata(metadata);
                } else {
                    console.error('Failed to fetch video metadata:', response.statusText);
                    setError('Failed to fetch video metadata');
                }
            } catch (error) {
                console.error('Error fetching video metadata:', error);
                setError('Error fetching video metadata');
            }
        };

        fetchVideoMetadata();
    }, [videoId, authHeader]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!videoMetadata) {
        return (
            <div className="loader_cv-container">
                <div className="loader_cv"></div> {/* Centered loader in video section */}
            </div>
        );
    }

    return (
        <>
            <div className="cv-content">
                <div className="cv-video-section">
                    <VideoPlayer
                        videoLink={`${CLOUDFRONT_URL}/videos/${videoMetadata.videoId}/HLS/${videoMetadata.videoId}.m3u8`}
                        videoMetadata={videoMetadata}
                    />
                </div>
            </div>
        </>
    );
}

export default CasesVideo;
