import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import './login.css';

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [usernameLogin, setUsernameLogin] = useState('');
  const [passwordLogin, setPasswordLogin] = useState('');
  const [name, setName] = useState('');

  /*const [usernameRegister, setUsernameRegister] = useState('');
  const [passwordRegister, setPasswordRegister] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [planType, setPlanType] = useState('');
  const [permissions, setPermissions] = useState('surgeon'); // Updated to allow dynamic selection
  */
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const signOut = useSignOut();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoggedIn(true);
      navigate("/cases_home");
    } else {
      setIsLoggedIn(false);
    }
  }, [isAuthenticated, navigate]);

  // Handle Login
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${API_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: usernameLogin,
          password: passwordLogin,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setError('');
        setIsLoggedIn(true);
        setUsernameLogin('');
        setPasswordLogin('');

        signIn({
          auth: {
            token: data.token
          },
          userState: { name: usernameLogin }
        });

        navigate("/cases_home");
      } else {
        setError(data.message || 'Login failed');
      }
    } catch (error) {
      setError('Error signing in');
    } finally {
      setLoading(false);
    }
  };

  /*
  // Handle Registration
  const handleRegister = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${API_URL}/user/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: usernameRegister,
          password: passwordRegister,
          specialisation: speciality,
          email,
          phoneNumber,
          name,
          organisation,
          permissions, // Include selected permissions
          planType,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setError('');
        setIsLoggedIn(true);
        setUsernameRegister('');
        setPasswordRegister('');

        signIn({
          token: data.token,
          expiresIn: 3600, // Adjust as needed
          tokenType: "Bearer",
          authState: { name: usernameRegister },
        });

        // Clear form fields
        clearRegisterForm();
        navigate("/cases_home");
      } else {
        setError(data.message || 'Registration failed');
      }
    } catch (error) {
      setError('Error signing up');
    } finally {
      setLoading(false);
    }
  };*/

  const handleLogout = () => {
    setIsLoggedIn(false);
    clearLoginForm();
    signOut();
    navigate("/");
  };

  // Helper to clear login form
  const clearLoginForm = () => {
    setUsernameLogin('');
    setPasswordLogin('');
  };

  /*
  // Helper to clear registration form
  const clearRegisterForm = () => {
    setUsernameRegister('');
    setPasswordRegister('');
    setEmail('');
    setPhoneNumber('');
    setName('');
    setSpeciality('');
    setOrganisation('');
    setPlanType('');
    setPermissions('surgeon'); // Reset to default
  };*/

  return (
    <div className="login-page">

      {!isLoggedIn ? (
        <>
          <div className="welcome-section">
            <div className="logo">InOperis</div>
            <p>Your one-stop solution for surgical video editing and management.</p>
          </div>
          <div className="auth-section">
            {/* Login Card */}
            <div className="auth-card">
              <h2>Login</h2>
              {error && <p className="auth-error">{error}</p>}
              {/* Wrap Login Form */}
              <form onSubmit={handleLogin}>
              <div className="auth-form-group">
  <input
    type="text"
    value={usernameLogin}
    onChange={(e) => setUsernameLogin(e.target.value)}
    required
    placeholder="Username"
  />
  <label>Username</label>
</div>
<div className="auth-form-group">
  <input
    type="password"
    value={passwordLogin}
    onChange={(e) => setPasswordLogin(e.target.value)}
    required
    placeholder="Password"
  />
  <label>Password</label>
</div>
                <button 
                  className="auth-button" 
                  type="submit" 
                  disabled={!usernameLogin || !passwordLogin || loading}>
                  {loading ? 'Logging in...' : 'Login'}
                </button>
              </form>
              {/*<div className="auth-links">
                <Link to="/forgot-password">Forgot password?</Link>
                <div>or</div>
                <Link to="/login-sms">Log in with SMS</Link>
      </div>*/}
            </div>

            {/* Register Card */}
            {/*
            <div className="auth-card">
              <h2>Register</h2>
              {error && <p className="auth-error">{error}</p>}
              <div className="auth-form-group">
                <label>Username</label>
                <input 
                  type="text" 
                  value={usernameRegister} 
                  onChange={(e) => setUsernameRegister(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Password</label>
                <input 
                  type="password" 
                  value={passwordRegister} 
                  onChange={(e) => setPasswordRegister(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Email</label>
                <input 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Phone Number</label>
                <input 
                  type="tel" 
                  value={phoneNumber} 
                  onChange={(e) => setPhoneNumber(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Name</label>
                <input 
                  type="text" 
                  value={name} 
                  onChange={(e) => setName(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Speciality</label>
                <input 
                  type="text" 
                  value={speciality} 
                  onChange={(e) => setSpeciality(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Organisation</label>
                <input 
                  type="text" 
                  value={organisation} 
                  onChange={(e) => setOrganisation(e.target.value)} 
                />
              </div>
              <div className="auth-form-group">
                <label>Plan Type</label>
                <select
                  value={planType}
                  onChange={(e) => setPlanType(e.target.value)}
                >
                  <option value="">Select a plan</option>
                  <option value="basic">Basic</option>
                  <option value="premium">Premium</option>
                </select>
              </div>

              <div className="auth-form-group">
                <label>Role</label>
                <select
                  value={permissions}
                  onChange={(e) => setPermissions(e.target.value)}
                >
                  <option value="surgeon">Surgeon</option>
                  <option value="resident">Resident</option>
                  <option value="student">Student</option>
                </select>
              </div>

              <button 
                className="auth-button" 
                onClick={handleRegister} 
                disabled={
                  !usernameRegister || !passwordRegister || !email || !phoneNumber || 
                  !name || !speciality || !organisation || !planType || !permissions || loading
                }>
                {loading ? 'Registering...' : 'Register'}
              </button>
            </div>
              */}
            
          </div>
        </>
      ) : (
        <div>
          <h2>Welcome, {name}!</h2>
          <button className="auth-button" onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default Login;