import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import './account.css';
import { useUploadProgress } from '../context/UploadProgressContext.js';

const API_URL = process.env.REACT_APP_API_URL;

const Account = () => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const { progress, isUploading, processing } = useUploadProgress();

  const [userInfo, setUserInfo] = useState(null);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);

  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupError, setGroupError] = useState(null);

  const [isManageMembersModalOpen, setIsManageMembersModalOpen] = useState(false);
  const [currentGroupMembers, setCurrentGroupMembers] = useState([]);
  const [memberError, setMemberError] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUserData = async () => {
        try {
          const token = authHeader.split(' ')[1];
          const userResponse = await axios.get(`${API_URL}/user/info`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setUserInfo(userResponse.data.userInfo);

          // Fetch user's groups
          const groupsResponse = await axios.get(`${API_URL}/group/my-groups`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setGroups(groupsResponse.data.groups);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError(error.response?.data?.message || 'Error fetching data');
        }
      };

      fetchUserData();
    }
  }, [isAuthenticated, authHeader]);

  const handleSignOut = () => {
    // Sign out from authentication and clear JWT cookie
    signOut();

    // Clear CloudFront signed cookies by setting expiration to a past date
    document.cookie = "CloudFront-Policy=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure=true; samesite=strict;";
    document.cookie = "CloudFront-Signature=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure=true; samesite=strict;";
    document.cookie = "CloudFront-Key-Pair-Id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure=true; samesite=strict;";

    // Optionally reload the page to refresh state or redirect to login page
    window.location.reload(); // To ensure that all components re-initialize
};

  const handleAddGroup = async () => {
    if (!groupName.trim()) {
      setGroupError('Group name is required.');
      return;
    }
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.post(
        `${API_URL}/group`,
        {
          groupName,
          description: groupDescription,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setGroups([...groups, response.data.group]);
      setIsAddGroupModalOpen(false);
      setGroupName('');
      setGroupDescription('');
      setGroupError(null);
    } catch (error) {
      console.error('Error adding group:', error);
      setGroupError(error.response?.data?.message || 'Error adding group');
    }
  };

  const loadUserOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.get(`${API_URL}/group/search/users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { query: inputValue },
      });
      const users = response.data.users;
      return users.map((user) => ({ value: user.memberToken, label: user.name }));
    } catch (error) {
      console.error('Error fetching user options:', error);
      return [];
    }
  };

  const handleEditGroup = async () => {
    if (!groupName.trim()) {
      setGroupError('Group name is required.');
      return;
    }
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.put(
        `${API_URL}/group/${currentGroup.groupId}`,
        {
          groupName,
          description: groupDescription,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setGroups(
        groups.map((group) =>
          group.groupId === currentGroup.groupId ? response.data.group : group
        )
      );
      setIsEditGroupModalOpen(false);
      setCurrentGroup(null);
      setGroupName('');
      setGroupDescription('');
      setGroupError(null);
    } catch (error) {
      console.error('Error editing group:', error);
      setGroupError(error.response?.data?.message || 'Error editing group');
    }
  };

  const handleDeleteGroup = async (groupId) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      try {
        const token = authHeader.split(' ')[1];
        await axios.delete(`${API_URL}/group/${groupId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setGroups(groups.filter((group) => group.groupId !== groupId));
      } catch (error) {
        console.error('Error deleting group:', error);
        setError(error.response?.data?.message || 'Error deleting group');
      }
    }
  };

  const openEditGroupModal = (group) => {
    setCurrentGroup(group);
    setGroupName(group.groupName);
    setGroupDescription(group.description || '');
    setIsEditGroupModalOpen(true);
  };

  const fetchGroupMembers = async (groupId) => {
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.get(`${API_URL}/group/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setCurrentGroupMembers(response.data.group.members);
    } catch (error) {
      console.error('Error fetching group members:', error);
      setMemberError(error.response?.data?.message || 'Error fetching group members');
    }
  };

  const openManageMembersModal = (group) => {
    setCurrentGroup(group);
    setIsManageMembersModalOpen(true);
    fetchGroupMembers(group.groupId);
  };

  const handleAddMembers = async (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return;
    }
    try {
      const token = authHeader.split(' ')[1];
      const memberTokensToAdd = selectedOptions.map((option) => option.value);

      await axios.post(
        `${API_URL}/group/${currentGroup.groupId}/members`,
        { memberTokens: memberTokensToAdd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      fetchGroupMembers(currentGroup.groupId);
    } catch (error) {
      console.error('Error adding members:', error);
      setMemberError(error.response?.data?.message || 'Error adding members');
    }
  };

  const handleRemoveMember = async (memberToken) => {
    if (window.confirm('Are you sure you want to remove this member?')) {
      try {
        const token = authHeader.split(' ')[1];
        await axios.delete(`${API_URL}/group/${currentGroup.groupId}/members`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { memberToken },
        });

        fetchGroupMembers(currentGroup.groupId);
      } catch (error) {
        console.error('Error removing member:', error);
        setMemberError(error.response?.data?.message || 'Error removing member');
      }
    }
  };

  if (!isAuthenticated) {
    return <div>Please log in to view your account information.</div>;
  }

  const groupItems = groups.map((group) => {
    const isOwner = group.isOwner;

    return (
      <li key={group.groupId} className="group-list-item">
        <div className="group-item">
          <div className="group-header">
            <h3 className="group-name">{group.groupName}</h3>
            {isOwner && <span className="owner-tag">Owner</span>}
          </div>
          <p className="group-description">{group.description}</p>
          {isOwner && (
            <div className="group-actions">
              <button
                className="edit-button"
                onClick={() => openEditGroupModal(group)}
              >
                Edit
              </button>
              <button
                className="manage-members-button"
                onClick={() => openManageMembersModal(group)}
              >
                Manage Members
              </button>
              <button
                className="delete-button"
                onClick={() => handleDeleteGroup(group.groupId)}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </li>
    );
  });

  return (
    <div className="account">
      {userInfo ? (
        <div>
          <div className="greeting">
            <h1>{userInfo.name}, M.D.</h1>
            <p className="discrete-info">
              {userInfo.organisation} &nbsp; | &nbsp; {userInfo.specialisation}
            </p>
          </div>

          <div className="group-management">
            <h2>Group Management</h2>
            <button
              className="add-group-button"
              onClick={() => setIsAddGroupModalOpen(true)}
            >
              Add New Group
            </button>
            {error && <div className="error">{error}</div>}

            {groups.length > 0 ? (
              <ul className="group-list">{groupItems}</ul>
            ) : (
              <p>You are not a member of any groups.</p>
            )}

            {isManageMembersModalOpen && currentGroup && (
              <div className="modal">
                <div className="modal-content">
                  <h3>Manage Members for {currentGroup.groupName}</h3>
                  {memberError && <div className="error">{memberError}</div>}

                  <h4>Current Members:</h4>
                  <ul className="member-list">
                    {currentGroupMembers.map((member) => (
                      <li key={member.memberToken}>
                        <span>
                          {member.name} ({member.email})
                        </span>
                        {!member.isCurrentUser && (
                          <button
                            className="remove-member-button"
                            onClick={() => handleRemoveMember(member.memberToken)}
                          >
                            Remove
                          </button>
                        )}
                      </li>
                    ))}
                  </ul>

                  <h4>Add Members:</h4>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={loadUserOptions}
                    onChange={(selectedOptions) => handleAddMembers(selectedOptions)}
                    placeholder="Search and select users..."
                  />

                  <div className="modal-actions">
                    <button
                      className="close-button"
                      onClick={() => {
                        setIsManageMembersModalOpen(false);
                        setCurrentGroup(null);
                        setCurrentGroupMembers([]);
                        setMemberError(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isAddGroupModalOpen && (
              <div className="modal">
                <div className="modal-content">
                  <h3>Add New Group</h3>
                  {groupError && <div className="error">{groupError}</div>}
                  <input
                    type="text"
                    placeholder="Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                  <textarea
                    placeholder="Description"
                    value={groupDescription}
                    onChange={(e) => setGroupDescription(e.target.value)}
                  />
                  <div className="modal-actions">
                    <button className="save-button" onClick={handleAddGroup}>
                      Create Group
                    </button>
                    <button
                      className="cancel-button"
                      onClick={() => {
                        setIsAddGroupModalOpen(false);
                        setGroupError(null);
                        setGroupName('');
                        setGroupDescription('');
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isEditGroupModalOpen && currentGroup && (
              <div className="modal">
                <div className="modal-content">
                  <h3>Edit Group</h3>
                  {groupError && <div className="error">{groupError}</div>}
                  <input
                    type="text"
                    placeholder="Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                  <textarea
                    placeholder="Description"
                    value={groupDescription}
                    onChange={(e) => setGroupDescription(e.target.value)}
                  />
                  <div className="modal-actions">
                    <button className="save-button" onClick={handleEditGroup}>
                      Save Changes
                    </button>
                    <button
                      className="cancel-button"
                      onClick={() => {
                        setIsEditGroupModalOpen(false);
                        setGroupError(null);
                        setCurrentGroup(null);
                        setGroupName('');
                        setGroupDescription('');
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {(isUploading || processing) && (
              <div className="upload-progress">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                {isUploading && <p>{progress}% uploaded</p>}
                {processing && <p>Processing...</p>}
              </div>
            )}

            <button onClick={handleSignOut} className="sign-out-button">
              Sign Out
            </button>
          </div>
        </div>
      ) : (
        <p>Loading user information...</p>
      )}
    </div>
  );
};

export default Account;