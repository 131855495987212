import React from 'react';
import './home.css';
import { ReactComponent as ManageIcon } from '../../assets/upload.svg';
import { ReactComponent as AnnotateIcon } from '../../assets/edit.svg';
import { ReactComponent as OptimizeIcon } from '../../assets/world.svg';
import { ReactComponent as SecureIcon } from '../../assets/lock.svg';
import Screenshot from '../../assets/screenshot.png';

const Home = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Thank you! We will get back to you soon.');
    e.target.reset();
  };

  return (
    <div className="container">
      {/* Page title and subtitle */}
      <div className="title-section">
        <h1>Leverage the full educational value in surgical videos.</h1>
        <h3>The most comprehensive surgical visualization tool on the market</h3>
      </div>

  
      {/* Section with catchy title and screenshot */}
      <div className="annotation-tools-section">
        <div className="annotation-text">
          <h2>Access Multiple Annotation Tools</h2>
        </div>
        <div className="annotation-image">
          <img
            src={Screenshot}
            alt="Tool Screenshot"
            className="tool-screenshot"
          />
        </div>
      </div>

      {/* The content div from the previous homepage */}
      <div className="content">
        <div className="feature">
          <div className="icon-container">
            <ManageIcon className="icon" />
          </div>
          <div className="text">Manage your surgical videos</div>
        </div>
        <div className="feature">
          <div className="icon-container">
            <AnnotateIcon className="icon" />
          </div>
          <div className="text">Annotate the content</div>
        </div>
        <div className="feature">
          <div className="icon-container">
            <OptimizeIcon className="icon" />
          </div>
          <div className="text">Optimized for teaching purposes</div>
        </div>
        <div className="feature">
          <div className="icon-container">
            <SecureIcon className="icon" />
          </div>
          <div className="text">Secure</div>
        </div>
      </div>

      {/* Contact form */}
      <div className="contact-section">
        <h2>Contact us to schedule a demo in your institution</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" placeholder="Your Name" required />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
          </label>
          <label>
            Message:
            <textarea name="message" placeholder="Your Message" required />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Home;
