import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'


import Home from './components/home/home.js';
import Login from './components/home/login.js';
import CasesVideo from './components/cases/cases_video.js';
import CasesHome from './components/cases/cases_home.js';
import Account from './components/auth/account.js';
import UploadVideo from './components/cases/UploadVideo.js';
import { UploadProgressProvider } from './components/context/UploadProgressContext.js'; // Import the provider


import './App.css';

// Create auth store
const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: false 
});

const App = () => {
    return (
        <AuthProvider store={store}>
            <Router>
                <UploadProgressProvider>
                    <AppContent />
                </UploadProgressProvider>
            </Router>
        </AuthProvider>
    );
};

const AppContent = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const signOut = useSignOut();
    const location = useLocation();
    const hideFooter = location.pathname.startsWith('/cases_video/');


    const handleLogoClick = () => {
        navigate('/');
    };

    const handleSignOut = () => {
        // Sign out from authentication and clear JWT cookie
        signOut();
    
        // Clear CloudFront signed cookies by setting expiration to a past date
        /*document.cookie = "CloudFront-Policy=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure=true; samesite=strict;";
        document.cookie = "CloudFront-Signature=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure=true; samesite=strict;";
        document.cookie = "CloudFront-Key-Pair-Id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure=true; samesite=strict;";
    */
        // Optionally reload the page to refresh state or redirect to login page
        navigate('/login'); 
        window.location.reload(); // To ensure that all components re-initialize
    };

    return (
        <div className="App">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>InOperis</div>
                <nav className="nav">
                    <Link to="/" className="nav-link">Home</Link>
                    {isAuthenticated && (
                        <>
                            <Link to="/cases_home" className="nav-link">Cases</Link>
                            <Link to="/account" className="nav-link">Account</Link>
                            <button className="nav-link logout-button" onClick={handleSignOut}>Logout</button>
                        </>
                    )}
                    {!isAuthenticated && (
                        <Link to="/login" className="nav-link">Login</Link>
                    )}
                </nav>
            </header>
            
            {/* Main Content Area */}
            <main className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={ <Login />} />                
                    <Route element={<AuthOutlet fallbackPath='/login' />}>
                        <Route path="/cases_home" element={<CasesHome />} />
                        <Route path="/cases_video/:videoId" element={<CasesVideo />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="/upload_video" element={<UploadVideo />} />
                    </Route>
                </Routes>
            </main>
            
            {!hideFooter && (
                <footer className="footer">
                    <div>© 2024 InOperis</div>
                    <div className="contact-info">
                        <div className="contact-item">
                            <p>Contact Us</p>
                            <p>Inoperis@gmail.com</p>
                        </div>
                        <div className="contact-item">
                            <p>Montreal</p>
                            <p>Quebec, Canada</p>
                        </div>
                    </div>
                </footer>
            )}

            
         </div>
    );
};

export default App;
