// File: src/components/UploadVideo.jsx

import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { v4 as uuidv4 } from 'uuid';
import './UploadVideo.css';
import { useUploadProgress } from '../context/UploadProgressContext.js';

const API_URL = process.env.REACT_APP_API_URL;

const UploadVideo = () => {
    const [videoFile, setVideoFile] = useState(null);
    const { 
      progress, 
      setProgress, 
      isUploading, 
      setIsUploading, 
      showNotification, 
      setProcessing, 
      processing 
    } = useUploadProgress();
    const [videoMetadata, setVideoMetadata] = useState({
        videoTitle: '',
        videoDescription: '',
        isPublic: false,
        patientGender: 'male',
        surgerySpeciality: '',
        surgeryType: '',
        operatedBy: [], // Array of userIds
        allowedUsers: [], // Array of userIds
        allowedGroups: [], // Array of groupIds
        operatedByGroups: [], // Array of groupIds
      });
    const navigate = useNavigate();
    const authHeader = useAuthHeader();

    const [groups, setGroups] = useState([]); // To store user's groups
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserGroups = async () => {
            try {
                const token = authHeader.split(' ')[1];
                const response = await axios.get(`${API_URL}/group/my-groups`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setGroups(response.data.groups);
            } catch (err) {
                console.error('Error fetching user groups:', err);
                setError(err.response?.data?.message || 'Error fetching user groups');
            }
        };

        fetchUserGroups();
    }, [authHeader]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVideoMetadata({ ...videoMetadata, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type !== 'video/mp4') {
            alert('Please upload a valid MP4 video file');
            setVideoFile(null); // Clear the file input if not an MP4
            return;
        }
        setVideoFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!videoFile) {
            alert('Please select a video file');
            return;
        }
    
        try {
            setIsUploading(true);
            const token = authHeader.split(' ')[1];
            const videoId = uuidv4();
            const totalSize = videoFile.size; // Total size of the file
            let uploadedBytes = 0; // To keep track of the total uploaded bytes
    
            // Step 1: Initiate the multipart upload
            const initiateResponse = await axios.post(
                `${API_URL}/storage/start-upload`,
                { videoId, fileType: videoFile.type },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { uploadId } = initiateResponse.data;
    
            // Step 2: Split the file into chunks and upload with parallelism
            const CHUNK_SIZE = 100 * 1024 * 1024; // 100MB
            const totalParts = Math.ceil(videoFile.size / CHUNK_SIZE);
            const parts = [];
            const uploadPromises = [];
            const maxConcurrency = 5;
    
            for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
                const start = (partNumber - 1) * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, videoFile.size);
                const chunk = videoFile.slice(start, end);
    
                // Function to handle the upload of each chunk
// Function to handle the upload of each chunk
const uploadChunk = async (partNum, chunkData) => {
    try {
        const signedUrlResponse = await axios.post(
            `${API_URL}/storage/upload-chunk`,
            { videoId, partNumber: partNum, uploadId, fileType: videoFile.type },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        const { signedUrl } = signedUrlResponse.data;

        // Upload the chunk and store the response
        const response = await axios.put(signedUrl, chunkData, {
            headers: { 'Content-Type': videoFile.type },
            onUploadProgress: (event) => {
                // Incrementally add the uploaded bytes for this part
                uploadedBytes += event.loaded;
                const progressValue = Math.min(Math.round((uploadedBytes / totalSize) * 100), 100);
                setProgress(progressValue);
            },
        });

        // Store ETag and PartNumber
        parts[partNum - 1] = {
            ETag: response.headers.etag.replace(/"/g, ""), // Remove quotes from ETag
            PartNumber: partNum,
        };
    } catch (error) {
        console.error(`Error uploading part ${partNum}:`, error);
        throw error;
    }
};
    
                // Add the upload promise and manage concurrency
                const uploadPromise = uploadChunk(partNumber, chunk).then(() => {
                    uploadPromises.splice(uploadPromises.indexOf(uploadPromise), 1);
                });
                uploadPromises.push(uploadPromise);
    
                if (uploadPromises.length >= maxConcurrency) {
                    await Promise.race(uploadPromises);
                }
            }
    
            // Wait for all uploads to complete
            await Promise.all(uploadPromises);
    
            // Step 3: Complete the multipart upload
            await axios.post(
                `${API_URL}/storage/complete-upload`,
                { videoId, uploadId, parts },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            // Step 4: Proceed with metadata saving and status polling as before
            setIsUploading(false);
            setProcessing(true);
            setProgress(100);
    
            const pollStatus = async () => {
                try {
                    const statusResponse = await axios.get(`${API_URL}/video/${videoId}/status`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (statusResponse.data.status === 'complete') {
                        showNotification('Video processed and ready!');
                        setProcessing(false);
                        navigate(`/video/${videoId}`);
                    } else {
                        setTimeout(pollStatus, 5000);
                    }
                } catch (error) {
                    console.error('Error checking processing status:', error);
                    setTimeout(pollStatus, 5000);
                }
            };
    
            pollStatus();
        } catch (error) {
            console.error('Error uploading video:', error);
            setIsUploading(false);
            setProcessing(false);
            setError(error.response?.data?.message || 'Error uploading video');
        }
    };

    // Fetch user groups for group selection
    const [userGroups, setUserGroups] = useState([]);
    
    useEffect(() => {
        const fetchUserGroups = async () => {
            try {
                const token = authHeader.split(' ')[1];
                const response = await axios.get(`${API_URL}/group/my-groups`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserGroups(response.data.groups);
            } catch (err) {
                console.error('Error fetching user groups:', err);
            }
        };

        fetchUserGroups();
    }, [authHeader]);

    // Function to load combined options for users and groups
    const loadCombinedOptions = async (inputValue) => {
        if (!inputValue) {
            return [];
        }
        try {
            const token = authHeader.split(' ')[1];
            // Fetch users
            const userResponse = await axios.get(`${API_URL}/user/search`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { query: inputValue },
            });
            const users = userResponse.data.users.map(user => ({
                value: user.userId,
                label: `User: ${user.name}`,
                type: 'user'
            }));

            // Fetch groups matching the input
            const filteredGroups = userGroups.filter(group => 
                group.groupName.toLowerCase().includes(inputValue.toLowerCase())
            ).map(group => ({
                value: group.groupId,
                label: `Group: ${group.groupName}`,
                type: 'group'
            }));

            return [
                {
                    label: 'Users',
                    options: users
                },
                {
                    label: 'Groups',
                    options: filteredGroups
                }
            ];
        } catch (error) {
            console.error('Error fetching combined options:', error);
            return [];
        }
    };

    // Handle selection changes for operatedBy and allowedUsers
    const handleSelectionChange = (selectedOptions, field) => {
        if (!selectedOptions) {
            setVideoMetadata({ ...videoMetadata, [field]: [] });
            return;
        }

        const selectedUserIds = new Set();
        selectedOptions.forEach(option => {
            if (option.type === 'user') {
                selectedUserIds.add(option.value);
            } else if (option.type === 'group') {
                const group = userGroups.find(g => g.groupId === option.value);
                if (group && group.members) {
                    group.members.forEach(memberId => selectedUserIds.add(memberId));
                }
            }
        });

        setVideoMetadata({ ...videoMetadata, [field]: Array.from(selectedUserIds) });
    };

    return (
        <div className="upload-editor-container">
            <h1 className="upload-title">Upload Video</h1>
            <h5 className="file-format-subtitle">Stay logged in until the video has been processed and uploaded.</h5>
            <form className="upload-video-form" onSubmit={handleSubmit}>
                {/* Video Title */}
                <div className="form-group">
                    <label htmlFor="videoTitle">Video Title<span className="required">*</span></label>
                    <input
                        type="text"
                        id="videoTitle"
                        name="videoTitle"
                        placeholder="Enter Video Title"
                        value={videoMetadata.videoTitle}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Video Description */}
                <div className="form-group">
                    <label htmlFor="videoDescription">Video Description<span className="required">*</span></label>
                    <textarea
                        id="videoDescription"
                        name="videoDescription"
                        placeholder="Enter Video Description"
                        value={videoMetadata.videoDescription}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>

                {/* Video File */}
                <div className="form-group">
                    <label htmlFor="videoFile">Video File<span className="required">*</span></label>
                    <input
                        type="file"
                        id="videoFile"
                        accept="video/mp4"
                        onChange={handleFileChange}
                        required
                    />
                </div>

                {/* Public/Private */}
                <div className="form-group">
                    <label htmlFor="isPublic">Visibility<span className="required">*</span></label>
                    <select
                        id="isPublic"
                        name="isPublic"
                        value={videoMetadata.isPublic}
                        onChange={(e) => setVideoMetadata({ ...videoMetadata, isPublic: e.target.value === 'true' })}
                        required
                    >
                        <option value="true">Public</option>
                        <option value="false">Private</option>
                    </select>
                </div>

                {/* Surgery Speciality */}
                <div className="form-group">
                    <label htmlFor="surgerySpeciality">Speciality of Surgery<span className="required">*</span></label>
                    <input
                        type="text"
                        id="surgerySpeciality"
                        name="surgerySpeciality"
                        placeholder="Enter Speciality of Surgery"
                        value={videoMetadata.surgerySpeciality}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Surgery Type */}
                <div className="form-group">
                    <label htmlFor="surgeryType">Type of Surgery<span className="required">*</span></label>
                    <input
                        type="text"
                        id="surgeryType"
                        name="surgeryType"
                        placeholder="Enter Type of Surgery"
                        value={videoMetadata.surgeryType}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Operated By */}
                <div className="form-group">
                    <label htmlFor="operatedBy">Operated By</label>
                    <AsyncSelect
                        id="operatedBy"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={loadCombinedOptions}
                        onChange={(selectedOptions) => handleSelectionChange(selectedOptions, 'operatedBy')}
                        placeholder="Select Users or Groups..."
                        classNamePrefix="react-select"
                    />
                </div>

                {/* Allowed Users */}
                <div className="form-group">
                    <label htmlFor="allowedUsers">Allowed Users</label>
                    <AsyncSelect
                        id="allowedUsers"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={loadCombinedOptions}
                        onChange={(selectedOptions) => handleSelectionChange(selectedOptions, 'allowedUsers')}
                        placeholder="Select Users or Groups..."
                        classNamePrefix="react-select"
                    />
                </div>



                {/* Submit Button */}
                <div className="form-group">
                    <button type="submit" className="upload-submit-button" disabled={isUploading}>
                        {isUploading ? 'Uploading...' : 'Upload'}
                    </button>
                </div>
            </form>

            {/* Upload Progress */}
            {(isUploading || processing) && (
                <div className="upload-progress">
                    <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                    {isUploading && <p>{progress}% uploaded</p>}
                    {processing && <p>Processing...</p>}
                </div>
            )}

            {/* Error Message */}
            {error && <div className="error">{error}</div>}
        </div>
    );

};

export default UploadVideo;