// UploadProgressContext.js
import React, { createContext, useContext, useState } from 'react';

const UploadProgressContext = createContext();

export const UploadProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [processing, setProcessing] = useState(false); // Add processing state

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const startUpload = () => {
    setProgress(0);
    setIsUploading(true);
    setProcessing(false); // Reset processing state when a new upload starts
  };

  const endUpload = () => {
    setIsUploading(false);
  };

  return (
    <UploadProgressContext.Provider value={{ 
      progress, 
      setProgress, 
      isUploading, 
      setIsUploading, 
      showNotification, 
      startUpload, 
      endUpload, 
      processing, 
      setProcessing 
    }}>
      {children}
      {notification && (
        <div className="notification">
          {notification}
        </div>
      )}
    </UploadProgressContext.Provider>
  );
};

export const useUploadProgress = () => useContext(UploadProgressContext);
